

.conditions {
  .ant-typography {
    text-align: center;
  }

  &__content {
    max-width: 780px;
    margin: 0 auto;
  }
}
