.partners {
  background: #EDF0DB;
  padding: 25px 0;
}
.partners-list {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  max-width: 985px;
  margin: 0 auto;


  img {
    margin: 0 10px;
    margin-bottom: 15px;
    transition: all .2s ease;
    &:hover {
      transform: scale(1.1);
    }
  }
}

.protect-msg {
  display: flex;
  align-items: center;
  font-size: 13px;
  line-height: 18px;
  max-width: 1200px;
  margin: 0 auto;

  img {
    flex: none;
    margin-right: 15px;
  }
}

.partner-link {
  text-align: center;
  padding: 15px 0;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 30px;

  a {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    font-size: 21px;
    font-weight: bold;
    color: #000;
  }
  img {
    max-width: 250px;
  }
}