

.testimonials {
  align-content: center;
  margin: 40px 0 100px 0;

  .ant-card-head {
    border-radius: 10px 10px 0 0;
  }

  .testimonial-card {
    margin: 15px 20px;

    &.ant-card {
      color: $textColor;
      background: $darkBox;
      border-color: rgba(250,250,250, .3);

      .ant-card-head-title {
        color: $textColor;
      }
      
      a {
        color: $primaryColor;
        text-decoration: underline;
        
        &:hover {
          text-decoration: none;
        }
      }
    }
  }
}
