.benefit-points {
  background: url("https://income-front-data.s3.fr-par.scw.cloud/goldrush/images/benefit-bg.png") no-repeat center / 100% 100%;
  position: relative;
  margin-top: 30px;

  @include media("<568px") {
    background: linear-gradient(90deg, #FFCA03 0%, #FFE81F 100%);
    border-radius: 25px;
  }
  &__wrap {
    max-width: 615px;
    margin-right: 100px;
    margin-left: auto;
    min-height: 430px;
    padding-top: 45px;
    padding-bottom: 20px;

    @include media("<1200px") {
      margin: 0;
      padding: 30px 15px;
    }
  }

  &__img {
    position: absolute;
    left: -40px;
    bottom: 0;
    width: 426px;

    @include media("<1200px") {
      display: none;
    }
    img {
      object-fit: contain;
      max-width: 100%;
    }
  }
}

.benefit-point {
  +.benefit-point {
    margin-top: 30px;
  }
  &__title {
    display: flex;
    align-items: flex-start;
    color: $primaryColor;
    line-height: 30px;
    font-size: 25px;
    text-transform: uppercase;
    font-weight: 700;

    @include media("<568px") {
      font-size: 18px;
    }
  }
  &__number {
    flex: none;
    border-radius: 50%;
    width: 30px;
    margin-right: 10px;
    font-size: 20px;
    display: block;
    color: #FFCC0E;
    text-align: center;
    background: $primaryColor;

  }
  &__text {
    font-size: 14px;
    line-height: 19px;
  }
}