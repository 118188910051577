.main-banner {
  z-index: 1;

  .slick-slider {
    .slick-dots {
      display: inline-block !important;
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      bottom: 45px;
      z-index: 1;
      width: auto;

      @include maxWidth($media_lg) {
        bottom: 20px;
      }
      li {
        width: fit-content;
        height: fit-content;
        margin: 0;
        &:not(:last-child) {
          margin: 0 5px 0 0;
        }
        &.slick-active {
          button {
            width: 45px;
            background: $primaryColor;
            border-radius: 21px;
          }
        }

        button {
          width: 24px;
          height: 6px;
          border-radius: 21px;
          background: rgba(90, 96, 101, 0.5);
          padding: 0;

          &:before {
            display: none;
          }
        }
      }
    }
  }

}

.banner-slide {
  position: relative;

  &__image {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;

    &::before{
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background: #000;
      opacity: 0;
      z-index: 2;
      @include media("<1200px") {
        opacity: .3;
      }
    }

    img {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;

      @include media("<568px") {
        width: 400vw;
        margin-left: -245vw;
      }
    }
  }

  &__content {
    min-height: 440px;
    position: relative;
    z-index: 5;
    padding-top: 45px;
    padding-bottom: 60px;

    @include media("<568px") {
      min-height: 360px;
      padding-top: 40px;
    }
  }
  &__title {
    font-weight: 900;
    font-size: 58px;
    line-height: 1;
    margin-bottom: 10px;
    font-family: $titleFont;
    color: #fff;
    text-transform: uppercase;

    @include media("<1200px") {
      font-size: 44px;
    }
    span{
      color: $primaryColor;
    }

    @include media("<568px") {
      font-size: 26px;
    }
  }
  &__title-text {
    font-family: $mainSansSerif;
    font-size: 24px;
    color: $primaryColor;
    font-weight: 700;

    @include media("<1200px") {
      font-size: 18px;
    }
  }
  &__sub-title {
    font-size: 14px;
    line-height: 1.75;
    color: rgba(250,250,250, .9);
    font-family: $mainSansSerif;
  }
  &__btn {
    margin-top: 30px;
  }
}