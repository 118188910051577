.nav-cards {
  margin-top: 50px;

  @include media("<568px") {
    margin-top: 30px;
  }
  &__list {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -10px;
    padding: 0;
    list-style: none;
  }
  &__list-item {
    width: 25%;
    padding: 0 6px;
    padding-bottom: 12px;

    @include media("<1366px") {
      width: 50%;
    }

    @include media("<=860px") {
      width: 50%;
    }
    @include media("<568px") {
      width: 100%;
    }
  }
}

.nav-card {
  height: 125px;
  background: $darkBox;
  display: flex;
  align-items: center;
  padding: 25px 30px;
  padding-right: 0px;
  border-radius: 25px;
  padding-left: 15px;

  &__title {
    font-weight: bold;
    font-size: 18px;
    line-height: 1;
    margin: 0;
    margin-bottom: 10px;
    font-family: $mainSansSerifBold;
    text-transform: uppercase;
  }
  &__icon {
    margin-left: auto;
    width: 80px;
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: auto;

    img {
      max-height: 100%;
    }
  }

  .btn {
    line-height: 15px;
    padding-left: 10px;
    padding-right: 15px;
    font-size: 15px;
    text-transform: capitalize;
  }
}
