.free-code {
  background: linear-gradient(90deg, #FFCA03 0%, #FFE81F 100%);
  border-radius: 25px;
  min-height: 245px;
  position: relative;
  padding:  20px 60px;
  display: flex;
  align-items: center;
  margin: 30px 0;

  @include media("<768px") {
    display: block;
    text-align: center;
  }

  &__wrap {
    max-width: 460px;

    @include media("<768px") {
      margin: 0 auto;
      max-width: 300px;
    }
  }
  &__img {
    max-width: 480px;
    margin-left: auto;
    margin-right: 20px;

    @include media("<768px") {
      margin: 30px auto;
    }
    img {
      max-width: 100%;
      object-fit: contain;
    }
  }
  &__title {
    font-weight: 700;
    font-size: 35px;
    line-height: 43px;
    margin-bottom: 15px;
  }
  &__text {
    font-size: 14px;
    line-height: 17px;
    margin-bottom: 25px;
  }
}